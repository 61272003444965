/* eslint-disable complexity, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events, react/forbid-component-props, sonarjs/cognitive-complexity */
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useContext, useRef } from 'react';
import { useSelector } from 'react-redux';
import { DEAL_LOCATION_TYPES } from '../../config/constants/page-types';
import { MAX_TITLE_DEAL_SIZE } from '../../config/setup/setup';
import { GO_TO_DEAL, VIEW } from '../../config/text/text';
import { initializeTracking } from '../../helpers/analytics';
import {
  textOverflow,
  useSocialCues,
  hidePrice,
  getUrlFromDeal,
  useDealImpresssion,
  showPostage,
  isClosedDeal,
} from '../../helpers/deals';
import { parseWowcherPath } from '../../helpers/url';
import useRestoreScroll from '../../helpers/useRestoreScroll';
import { showVideo } from '../../helpers/video';
import ThemeContext from '../../providers/ThemeProvider';
import DealPriceSplat from './shared-components/DealPriceSplat';
import DealSocialCueTop from './shared-components/DealSocialCueTop';
import DealSocialCuesBottom from './shared-components/DealSocialCuesBottom';
import SocialCueDealInfo from './shared-components/DealSocialCuesInfo';
import VipExclusiveTag from './shared-components/VipExclusiveTag';
import CarouselBlock from './shared-components/carousel/CarouselBlock';
import EmptyDeal from './shared-components/emptyDeal';

const WishlistIcon = dynamic(() => import('./shared-components/WishlistIcon'), {
  ssr: false,
});

const XlDealCard = ({ deal = null, socialcues = null, filtersURL }) => {
  const router = useRouter();
  const [
    ssr,
    isVipUser,
    mainDeal,
    secondaryDealsArray,
    trackingDeals,
  ] = useSelector((state) => [
    state.deals.ssr,
    state?.user?.userprofile?.vipUser,
    state?.deals?.mainDeal,
    state?.deals?.secondaryDealsArray,
    state?.tracking?.trackingDeals,
  ]);
  const theme = useContext(ThemeContext);
  const componentRef = useRef(null);
  const isClosed = isClosedDeal(deal, router.query, router.pathname);
  const socialCuesData = useSocialCues(
    deal?.id,
    isClosed || deal?.totalRemaining <= 0,
  );
  const location = useSelector((state) => state.locations.location.shortName);
  const [, setScrollData] = useRestoreScroll();

  useDealImpresssion({ componentRef, dealId: deal?.id, location });

  if (!deal || Object.keys(deal).length === 0) return <EmptyDeal />;

  const dealTitle = isVipUser && deal?.vipTitle ? deal?.vipTitle : deal?.title;

  const dealHeadline =
    isVipUser && deal?.vipHeadline ? deal?.vipHeadline : deal?.headline;
  const headLineAux = deal.headline ? hidePrice(dealHeadline, ssr) : '';
  const title = deal.title ? hidePrice(dealTitle, ssr) : '';
  const dealUrlPath = getUrlFromDeal({
    deal,
    originPath:
      filtersURL === '' || filtersURL === undefined
        ? router.asPath
        : filtersURL,
  });
  const withVideo = showVideo(deal) ? 'withvideo' : '';

  const pathUrl = parseWowcherPath(router.asPath);
  const discount = Boolean(
    pathUrl?.details?.locationType !== DEAL_LOCATION_TYPES.local,
  );
  const hasPostage = showPostage(deal, discount, true);
  const showVipExclusiveTag = isVipUser && deal?.display?.vipExclusive;

  const setScrollDataOnClick = () => {
    if (router.asPath.includes('deals')) {
      initializeTracking(deal, [
        mainDeal,
        ...secondaryDealsArray,
        ...trackingDeals,
      ]);
    } else {
      initializeTracking(deal, [...secondaryDealsArray, ...trackingDeals]);
    }

    setScrollData({
      dealId: deal.id,
      page: deal?.page,
      url: window.location.href,
    });
  };

  const reviewScore = deal.reviewSummary?.average || null;
  const reviewCount = deal.reviewSummary?.total || null;

  return (
    <div
      className={`card xl-deal deal_item ${withVideo}`}
      data-review-count={reviewCount}
      data-review-score={reviewScore}
      id={`deal_${deal.id}`}
      onClick={setScrollDataOnClick}
      ref={componentRef}
    >
      <div className="deal-image__container">
        <div className="shared-deal-image__container">
          <WishlistIcon dealId={deal.id} />
          <DealSocialCueTop
            socialCueClaim={deal?.socialCueClaim}
            socialCuesAvailability={deal.totalRemaining || 0}
            socialCuesBought={deal.totalBought || 0}
            socialCuesData={socialcues || socialCuesData}
          />
          <CarouselBlock
            deal={deal}
            href={dealUrlPath}
            showImageOverlays
            showScrim
            showSingle={deal.images?.length === 1}
            thumbWidth={54}
          />
          <div className="shared-deal--extra-container">
            <DealSocialCuesBottom
              deal={deal}
              dealTitle={dealTitle}
              discount={discount}
              show={hasPostage}
              socialCuesData={deal}
              socialCuesExtra={socialcues || socialCuesData}
            />

            <DealPriceSplat deal={deal} discount={discount} visible={!ssr} />
            {showVipExclusiveTag && (
              <VipExclusiveTag className="tag-before-splat" />
            )}
          </div>
          <div className="deal-info-container row">
            <div className="deal-info">
              <div className="deal-title">
                <Link as={dealUrlPath} href="/deal/[...slug]">
                  <a aria-label={GO_TO_DEAL.replace('##DEAL##', deal.headline)}>
                    <h3>{textOverflow(headLineAux, MAX_TITLE_DEAL_SIZE)}</h3>
                  </a>
                </Link>
              </div>
              <div className="deal-text">
                <Link as={dealUrlPath} href="/deal/[...slug]">
                  <a aria-label={GO_TO_DEAL.replace('##DEAL##', deal.headline)}>
                    <span>{textOverflow(title) + ' '}</span>
                  </a>
                </Link>
              </div>
            </div>
            <div className="deal-button row justify-content">
              <SocialCueDealInfo socialCuesData={deal} />
              <div className="button-view">
                <Link as={dealUrlPath} href="/deal/[...slug]">
                  <a aria-label={GO_TO_DEAL.replace('##DEAL##', deal.headline)}>
                    <div className="button-wrapper">
                      <div className="button-text">{VIEW}</div>
                    </div>
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .card.xl-deal {
          margin-top: 20px !important;
        }

        :global(.xl-deal .control-dots) {
          pointer-events: none;
        }

        .card {
          margin: 0;
          padding: 0;
          text-align: left;
          color: inherit;
          text-decoration: none;
          border-radius: 10px;
          overflow: hidden;
          transition: color 0.15s ease, border-color 0.15s ease;
          position: relative;
          background-color: ${theme.colors.dealbackgroundcolor};
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
        }

        .card.xl-deal {
          height: 632px;
          width: 727px;
        }

        .card:hover,
        .card:focus,
        .card:active {
          curser: pointer;
        }

        .card .deal-image__container {
          position: absolute;
          top: 0px;
          height: 100%;
          width: 100%;
          z-index: 1;
        }

        .card .deal-extra-container {
          z-index: 2;
          height: 70%;
          position: relative;
        }

        .card .deal-info-container {
          width: 100%;
          padding: 0;
          flex-grow: 1;
          position: relative;
        }

        .row {
          margin: 0px;
        }

        .card .deal-info {
          padding: 0;
          padding-left: 11px;
          padding-right: 11px;
          margin-top: 7px;
        }

        .card .deal-info .deal-text {
          width: 86%;
        }

        .card .deal-info a h3 {
          font-size: 19px;
          font-weight: bold;
          margin: 0;
          color: ${theme.colors.dealtext};
        }

        .card .deal-text a span {
          color: ${theme.colors.dealtext};
        }

        .card .deal-info a {
          text-decoration: none;
        }

        .card .deal-info .deal-info-container .deal-info {
          margin-top: 10px;
        }

        .card .deal-button {
          position: absolute;
          left: 0;
          bottom: 0;
          padding: 0;
        }

        .card .deal-button a .button-wrapper {
          height: 100%;
          cursor: pointer;
        }

        .card .deal-button a .button-text {
          background-color: ${theme.colors.dealviewbutton};
          display: block;
          text-align: center;
          color: #ffffff;
          font-weight: bold;
          font-size: 18px;
          border-radius: 2rem;
          padding: 0 1rem;
          text-decoration: none;
          width: 96px;
          text-transform: ${theme.text.cta.button};
          margin-top: 34px;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          height: 39px;
        }

        .justify-content {
          position: absolute;
          bottom: 0px;
          width: 100%;
        }

        .button-view {
          position: absolute;
          right: 14px;
          bottom: 40px;
          height: 48px;
        }

        .shared-deal-image__container {
          display: flex;
          flex-direction: column;
          position: relative;
          height: 100%;
        }

        .shared-deal-image__container .shared-deal--extra-container {
          position: relative;
        }

        .shared-deal-image__container img {
          width: 100%;
        }

        .social-cues-container__main-image__top {
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 999;
        }

        .social-cues-container__main-image__top .social-cue__item {
          width: 50%;
          margin: 10px auto;
          text-align: center;
          font-weight: bold;
          border-radius: 10px;
        }

        @media (max-width: ${theme.breakpoints.xlDown}) {
          //1199.98px
          // Not Ipad but too small for xl deal + small deal in the same row
          .card.xl-deal {
            width: 666px;
            height: 574px;
            margin: 0 auto;
          }
        }

        @media (max-width: ${theme.breakpoints.mdDown}) {
          // 768
          .card.xl-deal {
            width: 100%;
            // height: auto;
          }
          .button-view {
            bottom: 80px;
          }
        }

        @media (max-width: ${theme.breakpoints.smDown}) {
          // 575
          .card.xl-deal {
            border-radius: 0;
            height: auto;
          }

          .deal-image__container {
            position: relative !important;
          }

          .card .deal-info {
            position: absolute;
            bottom: 0;
            padding-bottom: 36px;
            width: 82%;
          }

          .card .deal-price {
            padding-left: 10px;
          }

          .card .deal-text,
          .card .now-text,
          .card .now-price {
            display: none;
          }

          .card .deal-info .deal-title h3 {
            color: ${theme.colors.dealmobiletitle};
            font-size: 16px;
            line-height: normal;
            font-weight: bold;
            margin-left: 10px;
          }
          .button-view {
            display: none;
          }
          .card .deal-info {
            padding-left: 0px;
            padding-right: 0px;
          }
        } // 575
      `}</style>
      <style global jsx>{`
        .card.xl-deal .single-image__image {
          width: 100%;
          height: auto;
        }
        .shared-deal-image__container .carousel:nth-child(2) {
          // position: absolute;
          position: inherit;
        }

        .xl-deal {
          position: relative;
        }

        .card.xl-deal .thumbs {
          position: absolute;
          bottom: 0px;
          margin: 0px;
          max-width: 46%;
          z-index: 1;
        }
        .card.xl-deal .slide {
          background-color: #ffffff;
        }

        @media (max-width: ${theme.breakpoints.xlDown}) {
          //1199.98px
          // Not Ipad but too small for xl deal + small deal in the same row
          .card.xl-deal .single-image__image {
            width: 100%;
            height: auto;
          }
          .card.xl-deal .carousel-root .carousel .slider-wrapper {
            max-height: 427px;
          }
          .shared-deal-image__container .carousel .thumb {
            width: 60px !important;
            height: auto;
          }
        } //1199.98px

        @media (max-width: ${theme.breakpoints.smDown}) {
          // 575
          .card.xl-deal .single-image__image {
            width: 100%;
            height: auto;
          }
          .card.xl-deal .carousel-root .carousel .slider-wrapper {
            //max-height: 335px;
          }
          .shared-deal-image__container .thumbs {
            display: none;
          }
          .shared-deal-image__container .social-cues-container__info {
            display: none;
          }
          .shared-deal-image__container
            .social-cues-container__main-image__bottom
            .bought-count {
            position: absolute;
            bottom: 10px;
            color: white;
            background: none;
            margin: 0;
            padding: 0;
          }

          .shared-deal-image__container
            .social-cues-container__main-image__bottom
            .social-cue__item.availability {
            display: none;
          }
        } // 575
      `}</style>
    </div>
  );
};

XlDealCard.propTypes = {
  deal: PropTypes.object,
  filtersURL: PropTypes.string,
  socialcues: PropTypes.object,
};

export default XlDealCard;
