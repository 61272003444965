import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { MARKETING_CLAIMS_DEF_LINK } from '../../../config/links/links';
import { LIMITED_AVAILABILITY } from '../../../config/text/text';
import { isIESite } from '../../../helpers/pages';
import ThemeContext from '../../../providers/ThemeProvider';

const DealSocialCuesInfo = ({ socialCuesData }) => {
  const theme = useContext(ThemeContext);
  const isIrelandSite = isIESite();

  // must have some remaining - totalRemaining
  if (!socialCuesData || !socialCuesData.totalRemaining) return <></>;

  const message = socialCuesData?.socialCueClaim;

  // only show if socialCuesData?.socialCueClaim is 'Limited availability'
  const socialCueMessage =
    message === LIMITED_AVAILABILITY ? LIMITED_AVAILABILITY : '';

  return (
    <>
      <div className="social-cues-container__info">
        {socialCueMessage && socialCueMessage.length ? (
          <div className="social-cue__item availability">
            <div className="social-text">{socialCueMessage}</div>{' '}
            {!isIrelandSite && (
              <FontAwesomeIcon
                icon={faInfoCircle}
                onClick={() => {
                  window.open(
                    MARKETING_CLAIMS_DEF_LINK[process.env.NEXT_PUBLIC_SITE],
                    '_blank',
                  );
                }}
                style={{
                  cursor: 'pointer',
                  marginLeft: '0.2rem',
                }}
              />
            )}
          </div>
        ) : (
          ''
        )}
      </div>
      <style jsx>{`
        .social-cues-container__info {
          position: relative;
          left: 50%;
        }

        .social-cues-container__info .social-cue__item {
          display: flex;
          max-width: 146px;
        }
        .social-cues-container__info .social-text {
          text-align: left;
          font-size: 16px;
          line-height: 1.2;
        }

        .social-cues-container__info .availability {
          min-height: 70px;
          background-color: ${theme.colors.dealavailability};
          color: ${theme.colors.textonprimary};
          font-weight: bold;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          text-align: center;
          padding-left: 16px;
          padding-right: 12px;
          padding-top: 6px;
          // position: absolute;
          // bottom: 0px;
        }
      `}</style>
    </>
  );
};
DealSocialCuesInfo.propTypes = {
  socialCuesData: PropTypes.object,
};
export default DealSocialCuesInfo;
