/* jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events, react/forbid-component-props
 */
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useContext, useRef } from 'react';
import { useSelector } from 'react-redux';
import { DEAL_LOCATION_TYPES } from '../../config/constants/page-types';
import {
  DEFAULT_MEDIUM_DEAL_IMG_HEIGHT,
  DEFAULT_MEDIUM_DEAL_IMG_WIDTH,
} from '../../config/setup/setup';
import { GO_TO_DEAL, VIEW } from '../../config/text/text';
import { initializeTracking } from '../../helpers/analytics';
import { isCreditecDeal } from '../../helpers/creditec';
import {
  getUrlFromDeal,
  hidePrice,
  showPostage,
  useDealImpresssion,
} from '../../helpers/deals';
import { parseWowcherPath } from '../../helpers/url';
import useRestoreScroll from '../../helpers/useRestoreScroll';
import ThemeContext from '../../providers/ThemeProvider';
import PrimaryButton from '../_generic/button/PrimaryButton';
import Clamp from '../_generic/utils/Clamp';
import DealPriceSplat from './shared-components/DealPriceSplat';
import DealSocialCueTop from './shared-components/DealSocialCueTop';
import DealSocialCuesBottom from './shared-components/DealSocialCuesBottom';
import PostageAndPackagingText from './shared-components/PostageAndPackagingText';
import VipExclusiveTag from './shared-components/VipExclusiveTag';
import CarouselBlock from './shared-components/carousel/CarouselBlock';
import DealCardWrapper from './shared-components/dealCardWrapper';
import EmptyDeal from './shared-components/emptyDeal';

const WishlistIcon = dynamic(() => import('./shared-components/WishlistIcon'), {
  ssr: false,
});

const MediumDeal = ({
  deal = null,
  socialcues = null,
  extraclasses = '',
  filtersURL,
}) => {
  const theme = useContext(ThemeContext);
  const [
    ssr,
    isVipUser,
    mainDeal,
    trackingDeals,
    secondaryDealsArray,
  ] = useSelector((state) => [
    state.deals.ssr,
    state?.user?.userprofile?.vipUser,
    state?.deals?.mainDeal,
    state?.tracking?.trackingDeals,
    state?.deals?.secondaryDealsArray,
  ]);
  const router = useRouter();
  const pathUrl = parseWowcherPath(router.asPath);
  const location = useSelector((state) => state.locations.location.shortName);
  const componentRef = useRef(null);
  const [, setScrollData] = useRestoreScroll();
  const isHeadlineBig = deal?.headline?.length > 58;
  const showVipExclusiveTag = isVipUser && deal?.display?.vipExclusive;

  useDealImpresssion({ componentRef, dealId: deal?.id, location });

  const dealHeadline =
    isVipUser && deal?.vipHeadline ? deal?.vipHeadline : deal?.headline;
  const headLineAux = deal.headline ? hidePrice(dealHeadline, ssr) : '';

  const dealUrlPath = getUrlFromDeal({
    deal,
    originPath:
      filtersURL === undefined || filtersURL === ''
        ? router.asPath
        : filtersURL,
  });
  const discount = pathUrl?.details?.locationType !== DEAL_LOCATION_TYPES.local;
  const hasPostage = showPostage(deal, discount, true);

  if (!deal || Object.keys(deal).length === 0) return <EmptyDeal />;

  const setScrollDataOnClick = () => {
    if (router.asPath.includes('deals')) {
      initializeTracking(deal, [
        mainDeal,
        ...secondaryDealsArray,
        ...trackingDeals,
      ]);
    } else {
      initializeTracking(deal, [...secondaryDealsArray, ...trackingDeals]);
    }
    setScrollData({
      dealId: deal.id,
      page: deal?.page,
      url: window.location.href,
    });
  };

  const reviewScore = deal.reviewSummary?.average || null;
  const reviewCount = deal.reviewSummary?.total || null;

  return (
    <>
      <div
        className={`medium-deal ${extraclasses}`}
        data-review-count={reviewCount}
        data-review-score={reviewScore}
        id={`deal_${deal.id}`}
        onClick={setScrollDataOnClick}
        ref={componentRef}
      >
        <DealCardWrapper>
          <div className="image-container">
            <DealSocialCueTop
              isMedium
              order={deal.order}
              socialCueClaim={deal?.socialCueClaim}
              socialCuesAvailability={deal.totalRemaining || 0}
              socialCuesBought={deal.totalBought || 0}
              socialCuesData={socialcues}
            />
            <CarouselBlock
              deal={deal}
              height={DEFAULT_MEDIUM_DEAL_IMG_HEIGHT}
              href={dealUrlPath}
              showImageOverlays
              showScrim
              single
              width={DEFAULT_MEDIUM_DEAL_IMG_WIDTH}
            />
            <DealPriceSplat deal={deal} discount={discount} />
            {showVipExclusiveTag && <VipExclusiveTag className="size--md" />}
            {!isCreditecDeal(deal) && (
              <DealSocialCuesBottom
                discount={discount}
                socialCuesData={deal}
                socialCuesExtra={socialcues}
              />
            )}
            <WishlistIcon dealId={deal.id} />
          </div>
          <Link as={dealUrlPath ?? ''} href="/deal/[...slug]">
            <a className="deal-info">
              <div className="deal-title">
                <Clamp lines={hasPostage ? 2 : 3}>
                  <h3 className="headline">{headLineAux}</h3>
                </Clamp>
                <div className="postage-text">
                  <PostageAndPackagingText
                    className="mobile-invert"
                    deal={deal}
                    discount={discount}
                    show={hasPostage}
                  />
                </div>
              </div>
              <PrimaryButton
                ariaLabel={GO_TO_DEAL.replace('##DEAL##', deal.headline)}
              >
                {VIEW}
              </PrimaryButton>
            </a>
          </Link>
        </DealCardWrapper>
      </div>

      <style jsx>{`
        :global(.medium-deal) {
          height: 306px;
          width: 353.33px;
        }
        .image-container {
          position: relative;
        }
        .deal-info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-grow: 1;
          width: 100%;
          padding: 0 10px;
          text-decoration: none;
        }
        .deal-title {
          flex-grow: 1;
        }
        .headline {
          font-size: 14px;
          font-weight: bold;
          margin: 0;
          color: ${theme.colors.dealtext};
        }
        .postage-text {
          margin-top: ${isHeadlineBig ? '-6px' : '0px'};
        }
        .medium-deal :global(.card) {
          width: 100%;
          height: 100%;
        }
        .medium-deal :global(.primary-button) {
          min-width: 96px;
          width: 96px;
          margin-left: 10px;
        }

        @media (max-width: ${theme.breakpoints.xlDown}) {
          :global(.medium-deal) {
            width: 323px;
            height: 290px;
          }
        }

        @media (max-width: ${theme.breakpoints.mdDown}) {
          .medium-deal {
            width: 273px;
            height: 256px;
          }
        }

        @media (max-width: ${theme.breakpoints.smDown}) {
          :global(.medium-deal) {
            width: 100% !important;
            height: auto !important;
          }
          .deal-info {
            position: absolute;
            bottom: 0;
          }
          .deal-info {
            position: absolute;
            bottom: 0px;
            left: 0;
            width: 100%;
            padding-bottom: 36px;
            padding-left: 10px;
            width: calc(100% - 90px);
          }
          .headline {
            color: ${theme.colors.dealmobiletitle};
            font-size: 16px;
            line-height: normal;
            font-weight: bold;
          }
          .medium-deal :global(.card) {
            width: 100%;
            height: auto;
            margin-bottom: 0;
          }
          .medium-deal :global(.primary-button) {
            display: none;
          }
          .medium-deal :global(.bought-count) {
            position: absolute;
            padding: 0;
            margin: 0;
            bottom: 10px;
            background-color: transparent;
            color: white;
          }
        }
      `}</style>
    </>
  );
};

MediumDeal.propTypes = {
  deal: PropTypes.object,
  extraclasses: PropTypes.string,
  filtersURL: PropTypes.string,
  socialcues: PropTypes.object,
};

export default MediumDeal;
